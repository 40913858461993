/*
 * Created by Andre Richards on 28 Aug 2020.
 */

import { useCallback, useEffect, useState } from "react";
import * as firebase from "firebase";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";
import PropTypes from "prop-types";

export const appNames = {
  ask: "AskNebula",
  crs: "NebulaCRS",
  pms: "NebulaPMS",
  pos: "NebulaPOS",
  hps: "Payment Switch",
};

export const defaultSignInSuccessUrls = {
  ask: "https://ask.hti.app",
  pos: "https://nebulapos.hti.app",
  pms: "https://nebulapms.hti.app",
  hps: "https://test-pay.hti.app",
};

export const defaultSessionUrls = {
  ask: "https://ask.hti.app/api/session/google_identity",
  pms: "https://nebulapms.hti.app/authSession",
  pos: "https://nebulapos.hti.app/authSession",
  hps: "https://test-pay.hti.app/authSession",
};

const useAuthState = (
  firebaseConfig,
  app,
  requireVerifiedEmail,
  authSessionUrl,
  signInSuccessUrl,
  signInSuccess,
  customAuthentication,
  logout
) => {

  useEffect(() => {
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig, "auth");
    }
  }, [firebaseConfig]);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [signInDisabled, setSignInDisabled] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [error, setError] = useState();
  const [action, setAction] = useState();

  // State-chart: see https://drive.google.com/file/d/1gNsfu3CYlXi1n4R_EMvPiwYe91cdBsXA/view?usp=sharing
  const [state, send] = useMachine(stateMachine);
  const showingSignIn_signedOut_forgotPasswordClicked = state.value?.showingSignIn?.signedOut?.forgotPasswordClicked;
  const showingSignUp = state.value?.showingSignUp;

  const doLogout = useCallback(() => {
    document.cookie =
      "hti-session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    firebase.auth(firebase.app("auth")).signOut();
  }, [app]);

  const showError = useCallback((error, action) => {
    setError(error);
    setAction(action);
  }, []);

  const clearMessages = useCallback(() => {
    setError(undefined);
    setAction(undefined);
  }, []);

  const errorAndLogout = useCallback(
    (error, action) => {
      showError(error, action);
      doLogout();
    },
    [doLogout, showError]
  );

  useEffect(() => {
    // console.log('useEffect - onAuthStateChanged');
    if (logout) {
      doLogout();
      var currURL = window.location.href;
      window.history.pushState(
        {},
        document.title,
        removeParam("logout", currURL)
      );
    }
    firebase.auth(firebase.app("auth")).onAuthStateChanged((user) => {
      /**
       * Called whenever the authentication state changes, i.e. the user logged in successfully or logged out.
       */
      // console.log('onAuthStateChanged: ' + JSON.stringify(user));
      if (user) {
        // User is signed in.
        send("SIGNED_IN");
        if (user.emailVerified) {
          send("VERIFIED");
        }
        if (!requireVerifiedEmail || user.emailVerified) {
          if (authSessionUrl) {
            firebase
              .auth(firebase.app("auth"))
              .currentUser.getIdToken(/* forceRefresh */ true)
              .then((idToken) => {
                signInSuccess(idToken);
              })
              .catch((error) => {
                /* eslint-disable no-console */
                console.error(error);
                /* eslint-enable no-console */
                errorAndLogout(error, "");
              });
          }
        } else {
          // don't have to do anything - a message that email address needs to be verified will be visible to the user based on 'state'
        }
      } else {
        // User is signed out.
        send("SIGNED_OUT");
      }
      setSpinner(false);
      setSignInDisabled(false);
    });
  }, [
    app,
    requireVerifiedEmail,
    signInSuccess,
    authSessionUrl,
    logout,
    doLogout,
    errorAndLogout,
    send,
  ]);

  useEffect(() => {
    // Update page title
    switch (app) {
      case "one":
        document.title = "NebulaONE";
        break;
      case "pos":
        document.title = "NebulaPOS";
        break;
      case "pms":
        document.title = "NebulaPMS";
        break;
      case "crs":
        document.title = "NebulaCRS";
        break;
      case "ask":
        document.title = "AskNebula";
        break;
      default:
        document.title = "NebulaONE";
    }
  }, [app]);

  /**
   * Handles the 'Send' password reset email button press.
   */
  const sendPasswordReset = useCallback(() => {
    clearMessages();
    if (email) {
      firebase
        .auth(firebase.app("auth"))
        .sendPasswordResetEmail(email, { url: window.location.href })
        .then(() => {
          send("PASSWORD_RESET_SENT");
        })
        .catch((error) => {
          /* eslint-disable no-console */
          console.error(error);
          /* eslint-enable no-console */
          let errorCode = error.code;
          let errorMessage = error.message;
          if (errorCode === "auth/invalid-email") {
            showError(errorMessage, "");
          } else if (errorCode === "auth/user-not-found") {
            showError(errorMessage, "");
          }
        });
    } else {
      showError("Please enter a valid email address.");
    }
  }, [clearMessages, email, send, showError]);

  /**
   * Handles the 'Sign in' button press.
   */
  const handleSignIn = useCallback(
      (email, password) => {
        setSignInDisabled(true);
        setSpinner(true);

        // if customAuthentication is specified, we do custom login and sign in to Firebase with CustomToken instead of email/password
        const userCredentialPromise = customAuthentication?.authFn
            ? customAuthentication
                .authFn(email, password)
                .then((customLogin) =>
                    firebase
                        .auth(firebase.app("auth"))
                        .signInWithCustomToken(customLogin.authCustomToken)
                )
            : firebase
                .auth(firebase.app("auth"))
                .signInWithEmailAndPassword(email, password);

        userCredentialPromise
            .then((userCredential) => userCredential.user.getIdToken())
            .then((idToken) => {
              // onAuthStateChanged (with validated user) will fire,
              // and it's handler will call signInSuccess, so we don't
              // have to do anything here
              // const csrfToken = getCookie('csrfToken');
            })
            .catch((err) => {
              /* eslint-disable no-console */
              console.error(err);
              /* eslint-enable no-console */
              const { code, message } = err;
              if (code === "auth/wrong-password") {
                showError(
                    "Wrong password.",
                    "Please try again or click on 'Forgot your password?' to reset it."
                );
              } else {
                showError(message);
              }
            });

        setSpinner(false);
        setSignInDisabled(false);
      },
      [customAuthentication, showError]
  );

  /**
   * Handles the 'Sign up' button press.
   */
  const handleSignUp = useCallback(() => {
    setSignInDisabled(true);
    setSpinner(true);
    clearMessages();
    firebase
        .auth(firebase.app("auth"))
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          send("SIGNED_IN");
          firebase
              .auth(firebase.app("auth"))
              .currentUser.sendEmailVerification({ url: window.location.href });
        })
        .then(() => {
          // console.log('firstName lastName:' + firstname + ' ' + lastname);
          firebase.auth(firebase.app("auth")).currentUser.updateProfile({
            displayName: firstname + " " + lastname,
          });
        })
        .then(() => {
          // Update successful.
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorCode === "auth/weak-password") {
            errorAndLogout(
                "The password is too weak.",
                "Please try again with a stronger password."
            );
          } else {
            errorAndLogout(errorMessage, "");
          }
          /* eslint-disable no-console */
          console.error(error);
          /* eslint-enable no-console */
        });
    setSignInDisabled(false);
    setSpinner(false);
  }, [
    email,
    password,
    firstname,
    lastname,
    clearMessages,
    errorAndLogout,
    send,
  ]);

  /**
   * Handles the 'form submit' button press.
   */
  const handleButton = useCallback(() => {
    clearMessages();
    if (firebase.auth(firebase.app("auth")).currentUser) {
      // [START signout]
      clearMessages();
      firebase.auth(firebase.app("auth")).signOut();
      // [END signout]
    } else {
      if (!email || email.length < 4) {
        showError("Please enter a valid email address.");
        return;
      }

      if (!showingSignIn_signedOut_forgotPasswordClicked) {
        if (!password || password.length < 1) {
          showError("Please enter a password.");
          return;
        }
      }

      if (showingSignUp) {
        if (firstname === undefined || firstname.length === 0) {
          errorAndLogout("Please enter a first name.");
          return;
        }
        if (lastname === undefined || lastname.length === 0) {
          errorAndLogout("Please enter a last name.");
          return;
        }
        handleSignUp();
      } else if (showingSignIn_signedOut_forgotPasswordClicked) {
        sendPasswordReset();
        send("PASSWORD_RESET_SENT");
      } else {
        handleSignIn(email, password);
      }
    }
  }, [
    clearMessages,
    email,
    errorAndLogout,
    firstname,
    lastname,
    password,
    send,
    sendPasswordReset,
    showError,
    handleSignIn,
    handleSignUp,
    showingSignIn_signedOut_forgotPasswordClicked,
    showingSignUp,
  ]);


  /**
   * used to show or hide the sign up or sign in fields
   */
  const toggleSignUpOrIn = useCallback(() => {
    clearMessages();
    send("TOGGLE_SIGN_UP_IN");
  }, [clearMessages, send]);

  /**
   * Handles the 'Resend verification email' click.
   */
  const resendEmailVerification = useCallback(() => {
    firebase
      .auth(firebase.app("auth"))
      .currentUser.sendEmailVerification()
      .then(() => {
        send("VERIFY_EMAIL_RESENT");
      })
      .catch((error) => {
        /* eslint-disable no-console */
        console.error(error);
        /* eslint-enable no-console */
        showError(error.message);
      });
  }, [send, showError]);

  /**
   * Handles the 'Forgot your password?' click.
   */
  const clickForgotPassword = useCallback(() => {
    clearMessages();
    send("FORGOT_PASSWORD_CLICKED");
  }, [clearMessages, send]);

  return {
    email,
    setEmail,
    password,
    setPassword,
    firstname,
    setFirstname,
    lastname,
    setLastname,
    signInDisabled,
    spinner,
    error,
    action,
    state,
    logout: doLogout,
    showError,
    clearMessages,
    errorAndLogout,
    handleButton,
    handleSignIn,
    handleSignUp,
    toggleSignUpOrIn,
    resendEmailVerification,
    clickForgotPassword,
    sendPasswordReset,
  };
};

export default useAuthState;

// Can be added as default 'leaf state' so you always have state as an object, instead of leaves being a string.
// Makes checking state easier and more consistent
const leafState = {
  initial: "leaf",
  states: {
    leaf: {},
  },
};

const signedOutStates = {
  initial: "none",
  states: {
    none: {
      on: {
        FORGOT_PASSWORD_CLICKED: "forgotPasswordClicked",
      },
      ...leafState,
    },
    forgotPasswordClicked: {
      on: {
        PASSWORD_RESET_SENT: "passwordResetSent",
      },
      ...leafState,
    },
    passwordResetSent: {
      ...leafState,
    },
  },
};

const verifyEmailStates = {
  initial: "verifyEmailSent",
  states: {
    verifyEmailSent: {
      on: {
        VERIFY_EMAIL_RESENT: "verifyEmailResent",
      },
      ...leafState,
    },
    verifyEmailResent: {
      ...leafState,
    },
  },
};

const verifiedStates = {
  initial: "unverified",
  states: {
    unverified: {
      on: {
        VERIFIED: "verified",
      },
      ...verifyEmailStates,
    },
    verified: {
      ...leafState,
    },
  },
};

const showingSignInStates = {
  initial: "signedOut",
  states: {
    signedOut: {
      on: {
        SIGNED_IN: "signedIn",
      },
      ...signedOutStates,
    },
    signedIn: {
      on: {
        SIGNED_OUT: "signedOut",
      },
      ...verifiedStates,
    },
  },
};

const showingSignUpStates = {
  initial: "signedOut",
  states: {
    signedOut: {
      on: {
        SIGNED_IN: "signedIn",
      },
      ...leafState,
    },
    signedIn: {
      on: {
        SIGNED_OUT: "signedOut",
      },
      ...verifiedStates,
    },
  },
};

const stateMachine = Machine({
  id: "hti-auth-ui",
  initial: "showingSignIn",
  states: {
    showingSignIn: {
      on: {
        TOGGLE_SIGN_UP_IN: "showingSignUp",
      },
      ...showingSignInStates,
    },
    showingSignUp: {
      on: {
        TOGGLE_SIGN_UP_IN: "showingSignIn",
      },
      ...showingSignUpStates,
    },
  },
});

useAuthState.propTypes = {
  firebaseConfig: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    authDomain: PropTypes.string.isRequired,
  }).isRequired,
  app: PropTypes.string,
  requireVerifiedEmail: PropTypes.bool,
  signInSuccessUrl: PropTypes.string,
  signInSuccess: PropTypes.func,
  authSessionUrl: PropTypes.string,
  customAuthentication: PropTypes.shape({
    authFn: PropTypes.func.isRequired,
  }),
};

useAuthState.defaultProps = {
  requireVerifiedEmail: true,
  app: "one",
  authSessionUrl: undefined,
};

function removeParam(key, sourceURL) {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}
