import React from 'react';
import {Auth} from 'lib';
import classes from './App.module.css';

const firebaseConfig = {
  apiKey: 'AIzaSyAz0-ZMzoXaIIKsWYwFG2d7tXob4JMBrDo',
  authDomain: 'nebulaone.firebaseapp.com',
  databaseURL: 'https://nebulaone.firebaseio.com',
  projectId: 'nebulaone',
  storageBucket: 'nebulaone.appspot.com',
  messagingSenderId: '1039434910690',
  appId: '1:1039434910690:web:d9f0711fc05ef4f39f07bb',
};

const App = () => (
  <div className={classes.container}>
    <Auth firebaseConfig={firebaseConfig} />
  </div>
);

export default App;
