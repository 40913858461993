/*
 * Created by Andre Richards on 28 Aug 2020.
 */

import React, {useCallback, useEffect, useMemo} from 'react';
import axios from "axios/index";
import PropTypes from 'prop-types';
import * as firebaseui from 'firebaseui';
import * as firebase from 'firebase';
import useAuthState, {
  defaultSessionUrls,
  defaultSignInSuccessUrls,
} from './useAuthState';

/**
 * Authentication widget for HTI products.
 *
 * Uses state-chart to keep track of components state.
 * @see https://xstate.js.org/docs/
 *
 * @param {Object} props - React Properties
 * Fields that can be passed inside props:
 * @param {Object} firebaseConfig
 * @param {string} [app] - app prop will be read from url parameters, but can also be specified with this prop if required. One of 'pos', 'pms', 'crs', 'ask' or 'one'. Order of preference is url parameter, then component prop, else will default to 'one'
 * @param {boolean} [requireVerifiedEmail=true] - if set to true, will only allow user to proceed (after login) if their email address has been verified
 * @param {string} [signInSuccessUrl='/'] - optional url to redirect browser to after successful login. If not set, will redirect to default Url for the given app (see defaultSignInSuccessUrls), else if no default is defined for the given app, '/'. Can be overridden with url parameters - useful for testing.
 * @param {string} [authSessionUrl] - optional url to fetch sessionToken from. This is used to fetch a application specific authorisation token (often a cookie). This is *not* the traditional 'SessionID' that is provided by a web server, and tied to that one server only. See https://firebase.google.com/docs/auth/admin/manage-cookies#java. Even though the article states that this is for 'traditional websites that rely on session cookies', please note that a session token/cookie has other advantages (as listed there) - specifically 'custom expiration times'.
 * @param {function} [customAuthentication] - called to authenticate a user, i.e. check if username and password are valid. By default authentication is done using Google Identity Platform's email/password call, so only specify this if you need to use your own custom authentication method. This is only provided for use with systems that have not been moved to Google Identity Platform.
 * @return {*}
 * @constructor
 */
const Auth = ({
  firebaseConfig,
  app = 'one',
  requireVerifiedEmail = true,
  signInSuccessUrl,
  authSessionUrl,
  customAuthentication,
}) => {
  const queryString = useMemo(() => window.location.search, []);
  const urlParams = useMemo(() => new URLSearchParams(queryString), [
    queryString,
  ]);

  const appActual = useMemo(() => urlParams.get('app') || app || 'one', [
    urlParams,
    app,
  ]);
  
  const signInSuccessUrlActual = useMemo(
    () => removeParam(
      'logout',
      urlParams.get('signInSuccessUrl')
          || signInSuccessUrl
          || defaultSignInSuccessUrls[appActual]
          || '/',
    ),
    [appActual, signInSuccessUrl, urlParams],
  );

  const authSessionUrlActual = useMemo(
    () => urlParams.get('authSessionUrl')
      || authSessionUrl
      || defaultSessionUrls[appActual],
    [urlParams, authSessionUrl, appActual],
  );
  const doPost = useMemo(
    () => urlParams.get('doPost')
      || false,
    [urlParams],
  );

  const signInSuccess = useCallback(
    (idToken) => {
      if (doPost) {
        axios
          .post(authSessionUrlActual, { idToken: idToken })
          .then((response) => {
            document.cookie = `hti-session=${response?.data?.sessionToken}; Max-Age=60; Path=/; Secure; SameSite=Lax;`;
            window.location.replace(signInSuccessUrlActual);
          })
          .catch((e) => console.log(e));
      } else {
        window.location.replace(
          `${authSessionUrlActual}?idToken=${idToken}&signInSuccessUrl=${signInSuccessUrlActual}`
        );
      }
    },
    [authSessionUrlActual, signInSuccessUrlActual]
  );

  const {
    email,
    setEmail,
    password,
    setPassword,
    firstname,
    setFirstname,
    lastname,
    setLastname,
    signInDisabled,
    spinner,
    error,
    action,
    state,
    handleButton,
    toggleSignUpOrIn,
    resendEmailVerification,
    clickForgotPassword,
  } = useAuthState(
    firebaseConfig,
    appActual,
    requireVerifiedEmail,
    authSessionUrlActual,
    signInSuccessUrlActual,
    signInSuccess,
    customAuthentication,
    urlParams.get('logout'),
  );

  useEffect(() => {
    // Initialize the FirebaseUI Widget using Firebase.
    const ui = firebaseui.auth.AuthUI.getInstance()
      || new firebaseui.auth.AuthUI(firebase.auth(firebase.app('auth')));

    if (!customAuthentication) {
      ui.start('#firebaseui-auth-container', {
        callbacks: {
          signInSuccessWithAuthResult: () => false,
        },
        signInFlow: 'popup',
        signInSuccessUrl: signInSuccessUrlActual,
        signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
        queryParameterForWidgetMode: 'mode',
        // Terms of service url.
        tosUrl:
          'http://www.htihospitality.tech/wp-content/uploads/2017/09/HTI_legal_disclaimer.pdf',
      });
    }
  }, [signInSuccessUrlActual, customAuthentication]);

  return (
    <div className="firebaseui-card-content">
      {/*<div>{JSON.stringify(state.value, null, 2)}</div>*/}

      <div className="signform">
        {appActual === 'one' && (
          <img
            className="logo"
            id="nebula_one_logo"
            src="nebula_one.jpg"
            style={{display: 'block'}}
          />
        )}
        {appActual === 'pos' && (
          <img
            className="logo"
            id="nebula_pos_logo"
            src="nebula_pos.png"
            style={{display: 'block'}}
          />
        )}
        {appActual === 'ask' && (
          <img
            className="logo"
            id="nebula_ask_logo"
            src="nebula_ask.png"
            style={{display: 'block'}}
          />
        )}
        {appActual === 'crs' && (
          <img
            className="logo"
            id="nebula_crs_logo"
            src="nebula_crs.png"
            style={{display: 'block'}}
          />
        )}
        {appActual === 'pms' && (
          <img
            className="logo"
            id="nebula_pms_logo"
            src="nebula_pms.png"
            style={{display: 'block'}}
          />
        )}

        <br />

        {state.value.showingSignUp?.signedOut && (
          <div className="headit">
            <div
              id="sign-in-text"
              className="sign-in-text"
              style={{display: 'inline-block'}}
            >
              <h>Already have an account?&nbsp;</h>
              <a
                href="javascript:;"
                id="quickstart-sign-up"
                onClick={toggleSignUpOrIn}
              >
                Sign In
              </a>
            </div>
          </div>
        )}

        {!customAuthentication && state.value.showingSignIn?.signedOut && (
          <div className="headit">
            <div
              id="sign-up-text"
              className="sign-in-text"
              style={{display: 'inline-block'}}
            >
              <div>Don't have an account?&nbsp;</div>
            </div>
            <a
              href="javascript:;"
              id="quickstart-sign-up"
              onClick={toggleSignUpOrIn}
            >
              Sign Up
            </a>
          </div>
        )}

        {spinner && (
          <div style={{textAlign: 'center'}}>
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        )}

        {!spinner && (
          <div className="input-group">
            <div id="error-group" className="error-group">
              {error && <p id="error-message">{error}</p>}
              {error && !action && <br />}
              {action && (
                <p id="error-action" className="error-action">
                  {action}
                </p>
              )}

              {state.value.showingSignIn?.signedOut?.forgotPasswordClicked && (
                <div>
                  <p className="error-action">
                    Enter your email address and we will send you a link to
                    reset your password.
                  </p>
                </div>
              )}

              {state.value.showingSignIn?.signedOut?.passwordResetSent && (
                <div>
                  <p id="info-message">
                    We have sent you a email to reset your password.
                  </p>
                  <p className="error-action">
                    Please open your email, and click on the link inside the
                    message.
                  </p>
                </div>
              )}

              {state.value.showingSignIn?.signedIn?.unverified
                ?.verifyEmailSent
                && !customAuthentication && (
                  <div>
                    <p id="info-message">
                      Your email address needs to be verified.
                    </p>
                    <p className="error-action">
                      Please open your email, and click on the link in the
                      message we sent you.
                    </p>
                  </div>
                )}

              {(state.value.showingSignUp?.signedIn?.unverified
                || state.value.showingSignIn?.signedIn?.unverified
                  ?.verifyEmailResent) && (
                  <div>
                    <p id="info-message">
                      We have sent you a verification email.
                    </p>
                    <p className="error-action">
                      Please open your email, and click on the link inside the
                      message.
                    </p>
                  </div>
              )}

              {(state.value.showingSignIn?.signedIn?.verified
                || (customAuthentication
                  && state.value.showingSignIn?.signedIn)) && (
                  <div>
                    <p id="info-message">You logged in successfully.</p>
                    <p className="error-action">
                      Please hold on while we load the app.
                    </p>
                  </div>
              )}

              {(state.value.showingSignIn?.signedIn?.unverified
                || state.value.showingSignUp?.signedIn?.unverified) && (
                <div>
                  <div style={{textAlign: 'center'}}>
                    <a
                      href="javascript:;"
                      className="error-group"
                      id="resend-verification-email"
                      onClick={resendEmailVerification}
                    >
                      Resend verification email
                    </a>
                  </div>
                  <br />
                </div>
              )}
            </div>

            {!state.value.showingSignIn?.signedOut?.passwordResetSent
              && !state.value.showingSignIn?.signedIn
              && !state.value.showingSignUp?.signedIn && (
                <input
                  className="mdl-textfield__input"
                  style={{display: 'inline', width: 'auto'}}
                  type="text"
                  id="email"
                  name="email"
                  placeholder={
                    customAuthentication
                      ? customAuthentication.usernameField
                      : 'Email'
                  }
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleButton();
                    }
                  }}
                />
            )}

            {!state.value.showingSignIn?.signedOut?.forgotPasswordClicked
              && !state.value.showingSignIn?.signedOut?.passwordResetSent
              && !state.value.showingSignIn?.signedIn
              && !state.value.showingSignUp?.signedIn && (
                <input
                  className="mdl-textfield__input"
                  style={{display: 'inline', width: 'auto'}}
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleButton();
                    }
                  }}
                />
            )}

            {state.value.showingSignUp && !state.value.showingSignUp?.signedIn && (
              <div id="sign-up-fields" style={{display: 'block'}}>
                <input
                  className="mdl-textfield__input"
                  style={{display: 'inline', width: 'auto'}}
                  type="text"
                  id="first-name"
                  name="first-name"
                  placeholder="First Name"
                  value={firstname}
                  onChange={(event) => setFirstname(event.target.value)}
                />
                <input
                  className="mdl-textfield__input"
                  style={{display: 'inline', width: 'auto'}}
                  type="text"
                  id="last-name"
                  name="last-name"
                  placeholder="Last Name"
                  value={lastname}
                  onChange={(event) => setLastname(event.target.value)}
                />
              </div>
            )}

            <div className="firebaseui-card-content">
              <span className="mdl-password-reset">
                {state.value.showingSignIn?.signedOut
                  && !state.value.showingSignIn?.signedOut
                    ?.forgotPasswordClicked
                  && !customAuthentication && (
                    <a
                      href="javascript:;"
                      id="quickstart-password-reset"
                      style={{display: 'block'}}
                      name="forgot-password"
                      onClick={clickForgotPassword}
                    >
                      Forgot your password?
                    </a>
                  )}
              </span>
              <br />
              {!state.value.showingSignIn?.signedIn?.unverified
                && !state.value.showingSignUp?.signedIn?.unverified && (
                  <button
                    disabled={signInDisabled}
                    className="mdl-button mdl-button--raised mdl-button-left"
                    id="quickstart-sign-in"
                    name="signin"
                    onClick={(e) => {
                      e.preventDefault();
                      handleButton();
                    }}
                  >
                    {state.value.showingSignUp?.signedIn
                    || state.value.showingSignIn?.signedIn
                      ? 'Sign Out'
                      : state.value.showingSignUp
                        ? 'Register'
                        : state.value.showingSignIn?.signedOut
                          ?.forgotPasswordClicked
                          ? 'Send'
                          : 'Sign In'}
                  </button>
              )}
            </div>
          </div>
        )}

        {!customAuthentication && (
          <div className="line">
            <span className="line-center">
              <span></span>
              {' '}
              Or
              <span></span>
            </span>
          </div>
        )}

        <div
          className="firebaseui-auth-container"
          id="firebaseui-auth-container"
        >
        </div>

        <div className="terms">
          <p>
            <a
              href="http://www.htihospitality.tech/wp-content/uploads/2017/09/HTI_legal_disclaimer.pdf"
              className="firebaseui-link firebaseui-tos-link"
              target="_blank"
            >
              Disclaimer | Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Auth;

Auth.propTypes = {
  firebaseConfig: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    authDomain: PropTypes.string.isRequired,
  }).isRequired,
  app: PropTypes.string,
  requireVerifiedEmail: PropTypes.bool,
  signInSuccessUrl: PropTypes.string,
  authSessionUrl: PropTypes.string,
  customAuthentication: PropTypes.shape({
    authFn: PropTypes.func.isRequired,
    usernameField: PropTypes.string.isRequired,
  }),
};

Auth.defaultProps = {
  requireVerifiedEmail: true,
  app: 'one',
  authSessionUrl: undefined,
  signInSuccessUrl: '/',
  customAuthentication: undefined,
};

function removeParam(key, sourceURL) {
  var rtn = sourceURL.split('?')[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    params_arr = queryString.split('&');
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split('=')[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + '?' + params_arr.join('&');
  }
  return rtn;
}
